.carousel-item {
  height: calc(100vh - 11.625em);
}
.carousel-caption h1 {
  margin-top: 0;
  color: white;
  text-shadow: 0.085em 0.09em 0.1em rgba(0, 0, 0, 1);
}
.carousel-caption h4 {
  margin-top: 1.5%;
  color: white;
  text-shadow: 0.085em 0.09em 0.3em rgba(0, 0, 0, 1);
}
.carousel-caption {
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-carousel {
  margin-top: 4%;
  font-weight: 700;
  padding: 0.3em 2em;
}

.index-card {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 30px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.indexSocialLinks__items {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.indexSocialLinks__item {
  color: black;
  margin-bottom: 10px;
}

.indexSocialLinks__item svg {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 100%;
  margin-right: 12px;
}

@media screen and (max-width: 1200px) {
  .carousel-item {
    height: auto;
  }
}

@media screen and (max-width: 960px) {
  .carousel-item {
    height: auto;
  }

  .carousel-caption h1 {
    font-size: 24px;
  }

  .carousel-caption h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .carousel-item {
    height: auto;
  }

  .carousel-caption h1 {
    font-size: 21px;
  }

  .carousel-caption h4 {
    font-size: 16px;
  }

  .btn {
    padding: 0.37rem 0.25rem;
  }

  .index-card-block {
    flex-direction: column;
    justify-content: center;
  }

  .index-card {
    margin: 0 auto;
  }

  .indexSocialLinks__items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.5em;
  }

  .indexSocialLinks__items a {
    margin: 0 5%;
  }

  .indexSocialLinks__item svg {
    width: 50px;
    height: 50px;
  }

  .indexSocialLinks__items span {
    display: none;
  }

  .indexSocialLinks__items h5 {
    display: none;
  }

  .indexSocialLinks__item {
    padding-left: 0;
    margin-left: 0;
  }

  .button-carousel {
    font-weight: 700;
    padding: 0.3em 1em;
    margin-top: 4%;
  }
}

@media screen and (max-width: 560px) {
  .carousel-caption {
    top: 40%;
    width: 80%;
  }
}

@media screen and (max-width: 460px) {
  p {
    font-size: 15px;
  }
  .carousel-item {
    height: auto;
  }

  .carousel-caption {
    top: 42%;
    width: 90%;
  }

  .carousel-control-prev {
    display: none;
  }

  .carousel-control-next {
    display: none;
  }

  .carousel-indicators {
    display: none;
  }

  .carousel-caption h1 {
    font-size: 20px;
  }

  .carousel-caption h4 {
    font-size: 15px;
  }

  .button-carousel {
    font-size: 15px;
    /*font-weight: 700;*/
  }

  .carousel-indicators {
    display: none;
  }
}
