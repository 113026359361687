footer {
  background: rgb(20, 82, 131);
  font-size: 15px;
  padding-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.bottom-text {
  color: white;
}

.bottom-text h1 {
  margin-top: 15px;
  font-size: 16px;
}

.bottom-text p {
  font-size: 15px;
}

.bottom-bar {
  text-align: center;
  color: white;
}

.footer-menu h1 {
  margin-top: 15px;
  font-size: 16px;
}

.footer-menu a {
  text-decoration: underline;
  color: white;
  padding: 0;
}
.footer-menu a:hover,
.footer-menu a:active,
.footer-menu a:focus {
  opacity: 0.65;
}

.bottom-logo {
  margin-top: 15px;
  display: inline-block;
  color: white;
  text-align: right;
}
.bottom-logo img {
  margin-right: 10px;
}

.bottom-logo a {
  text-decoration: underline;
  color: white;
}
.bottom-logo a:hover,
.bottom-logo a:active,
.bottom-logo a:focus {
  opacity: 0.65;
}

.site-info {
  display: flex;
  color: white;
  justify-content: space-around;
  font-size: 12px;
}

.lexicon-icon {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

@media screen and (max-width: 768px) {
  .mob {
    flex-direction: column;
    align-items: center;
  }

  .form-text {
    padding: 0;
  }

  .list-form {
    text-align: center;
    justify-content: center;
  }

  .bottom-text {
    text-align: center;
  }

  .bottom-text p {
    text-align: center;
  }

  .bottom-bar {
    text-align: center;
  }

  .footer-menu h1 {
    margin-top: 0;
    font-size: 14px;
  }

  .footer-menu a {
    font-size: 12px;
  }
  .bottom-logo {
    text-align: center;
    margin-bottom: 1em;
  }

  .bottom-logo a {
    font-size: 12px;
    padding: 0;
  }
}
