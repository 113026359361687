.cart-contact {
    padding-bottom: 20px;
    border-bottom: 1px solid #9d9c9b;
}

.cart-contact-text {
    padding-left: 0;
}

.cart-contact-text img {
    width: 100%;

}


.requisites {
    background: rgba(216, 216, 216, 0.5);
    border-radius: 10px;
}

@media screen and (max-width: 480px) {
    .contact-text {
        font-size: 12px;
    }

    .contact-text h3 {
        font-size: 14px;
    }

}