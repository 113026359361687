.header {
  margin-top: 10px;
}

.logo {
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
}

.header-text {
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 20px;
  margin-top: 15px;
  font-family: Ubuntu;
  color: #004a9e;
  letter-spacing: 1px;
}

.g-center {
  font-size: 19px;
  text-align: center;
  border-bottom: 1px solid rgb(20, 82, 131);
  margin: 0;
}

.g-center-1 {
  font-size: 19px;
  text-align: center;
  border-bottom: 1px solid rgb(20, 82, 131);
  margin: 0;
  letter-spacing: 10px;
}

.g-center:last-child {
  border-bottom: none;
}

.nav-block {
  background: rgb(20, 82, 131);
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  background: rgb(20, 82, 131);
}

.navbar-light .navbar-nav .show > .nav-link {
  color: white;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  text-transform: uppercase;
  font-family: Ubuntu;
}

.navbar-light .navbar-toggler {
  color: #fff;
  border-color: #fff;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #5dcfad;
}

.dropdown-item {
  color: white;
  font-size: 18px;
}

.dropdown-item:hover {
  color: #5dcfad;
  background: rgb(20, 82, 131);
}

@media screen and (max-width: 1200px) {
  .g-center {
    font-size: 16px;
  }

  .g-center-1 {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .logo {
    width: 80px;
    height: 80px;
  }

  .g-center {
    font-size: 12px;
  }

  .g-center-1 {
    font-size: 12px;
  }
}

@media screen and (max-width: 770px) {
  .logo-block {
    width: 100%;
    text-align: center;
  }

  .header-text {
    display: none;
  }

  .nav-link {
    font-size: 14px;
  }

  .dropdown-item {
    font-size: 12px;
  }
}
