@media screen and (max-width: 600px) {

    .block-form-group {
        flex-direction: column;
    }

    .list-form {
         flex-direction: column;
    }

}

.form {
    margin-top: 20px;
    margin-bottom: 40px;

}

.form-group {
    margin-bottom: 30px;
}

.form-span {
    width: 100%;
    border: 1px solid #2b2b28;
    padding: 10px 0;
    margin-bottom: 10px;
    border-radius: 25px;
    cursor: pointer;
}

.form-span:hover {
    box-shadow: inset 0 0 3px 2px #003aff;
}

.form-text {
    display: inline-block;
    margin-left: 5px;
}

.form-span input {
    margin-left: 20px;
    margin-top: 10px;

}

.form-span label {
    margin-left: 10px;
}

.textarea-group {

    width: 100%;

}

.textarea-group textarea {

    width: 100%;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding-left: 20px;
    outline: none;
    resize: none;
}

.textarea-group textarea:focus {

    box-shadow: inset 0 0 3px 2px #003aff;

}